import React from 'react';
import FreightOrdersService from '../services/FreightOrdersService';
import OrdersService from '../services/OrdersService';
import RunsService from '../services/RunsService';
import SchedulesService from '../services/SchedulesService';
import StopsService from '../services/StopsService';
import TasksService from '../services/TasksService';
import TlesService from '../services/TlesService';
import VehiclesService from '../services/VehiclesServcie';
import LocationsService from '../services/LocationsService';
import {AuthService, ToastService, TwoToast, FileService, UsersService} from 'two-app-ui';
import FreightProofsService from '../services/FreightProofsService';
import {TopFrame} from '../components/TopFrame/TopFrame';

type AppContextProps = {
  authService: AuthService;
  usersService: UsersService;
  schedulesService: SchedulesService;
  stopsService: StopsService;
  runsService: RunsService;
  tasksService: TasksService;
  freightOrdersService: FreightOrdersService;
  vehiclesService: VehiclesService;
  tlesService: TlesService;
  ordersService: OrdersService;
  toastService: ToastService;
  locationsService: LocationsService;
  twoToast: TwoToast;
  freightProofsService: FreightProofsService;
  fileService: FileService;
  appToolbarRef: React.RefObject<TopFrame>;
};

export const AppContext = React.createContext<Partial<AppContextProps>>({});
export default AppContext;
