import React from 'react';
import {AppContext} from './context/AppContext';
import SchedulesService from './services/SchedulesService';
import StopsService from './services/StopsService';
import RunsService from './services/RunsService';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faCheck,
  faCalendarAlt,
  faSignOutAlt,
  faSignInAlt,
} from '@fortawesome/pro-light-svg-icons';
import {faArrowAltToTop, faArrowAltToBottom, faNote} from '@fortawesome/pro-solid-svg-icons';
import './scss/App.scss';
import TasksService from './services/TasksService';
import FreightOrdersService from './services/FreightOrdersService';
import VehiclesService from './services/VehiclesServcie';
import TlesService from './services/TlesService';
import OrdersService from './services/OrdersService';
import {AuthService, FileService, UsersService, TwoToast, ToastService, ObaFrame} from 'two-app-ui';
import LocationsService from './services/LocationsService';
import FreightProofsService from './services/FreightProofsService';
import {Toast} from 'primereact/toast';
import {TopFrame} from './components/TopFrame/TopFrame';
import {Route, Switch} from 'react-router-dom';
import ScheduleComponent from './components/Schedule/ScheduleComponent';
import OrderComponent from './components/Order/OrderComponent';
import ReOrderStopsComponent from './components/Run/ReOrderStopsComponent';
import TaskComponent from './components/Task/TaskComponent';
import AddOrdersToRunComponent from './components/Run/AddOrdersToRunComponent';
import ProofComponent from './components/Proof/ProofComponent';
import RunComponent from './components/Run/RunComponent';
import VehicleComponent from './components/Vehicle/VehicleComponent';

library.add(
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faCheck,
  faCalendarAlt,
  faSignOutAlt,
  faSignInAlt,
  faArrowAltToTop,
  faArrowAltToBottom,
  faNote
);

const authService = new AuthService();
const usersService = new UsersService(authService);
const schedulesService = new SchedulesService(authService);
const stopsService = new StopsService(authService);
const runsService = new RunsService(authService);
const tasksService = new TasksService(authService);
const freightOrdersService = new FreightOrdersService(authService);
const vehiclesService = new VehiclesService(authService);
const tlesService = new TlesService(authService);
const ordersService = new OrdersService(authService);
const toastService = new ToastService();
const locationsService = new LocationsService(authService);
const toastRef = React.createRef<Toast>();
const twoToast = new TwoToast(toastRef);
const freightProofsService = new FreightProofsService(authService);
const fileService = new FileService(authService);
const appToolbarRef = React.createRef<TopFrame>();

export class App extends React.Component<{}> {
  constructor(props = {}) {
    super(props);
    this.state = {};
  }

  renderTopFrame() {
    return <TopFrame ref={appToolbarRef} />;
  }

  render() {
    const value = {
      authService: authService,
      schedulesService: schedulesService,
      stopsService: stopsService,
      runsService: runsService,
      tasksService: tasksService,
      freightOrdersService: freightOrdersService,
      usersService: usersService,
      vehiclesService: vehiclesService,
      tlesService: tlesService,
      ordersService: ordersService,
      toastService: toastService,
      locationsService: locationsService,
      twoToast: twoToast,
      freightProofsService: freightProofsService,
      fileService: fileService,
      appToolbarRef: appToolbarRef,
    };
    return (
      <AppContext.Provider value={value}>
        <ObaFrame contextValues={value} topFrame={this.renderTopFrame()}>
          <div className="p-d-flex p-flex-column p-p-4">
            <Switch>
              <Route path="/schedule">
                <ScheduleComponent />
              </Route>
              <Route path="/run/:runId/order/:id">
                <OrderComponent />
              </Route>
              <Route path="/run/:runId/re-order-stops">
                <ReOrderStopsComponent />
              </Route>
              <Route path="/run/:runId/task/:id">
                <TaskComponent />
              </Route>
              <Route path="/run/:runId/stop/:stopId/add-orders-to-run">
                <AddOrdersToRunComponent />
              </Route>
              <Route path="/run/:runId/stop/:stopId/proof/">
                <ProofComponent />
              </Route>
              <Route path="/run/:id">
                <RunComponent />
              </Route>
              <Route path="/">
                <VehicleComponent />
              </Route>
            </Switch>
          </div>
        </ObaFrame>
        <Toast ref={toastRef} />
      </AppContext.Provider>
    );
  }
}

export default App;
