import React from 'react';
import AppContext from '../../context/AppContext';
import {Toast} from 'primereact/toast';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {ProgressSpinner} from 'primereact/progressspinner';
import {QueryParameter, Run, Stop, Location, TimeLineEvent, TleContentNote} from 'two-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import StopsService from '../../services/StopsService';
import RunsService from '../../services/RunsService';
import './ReOrderStopsComponent.scss';
import TasksService from '../../services/TasksService';
import FreightOrdersService from '../../services/FreightOrdersService';
import {LocationType} from 'two-core/build/esm/src/location';
import TlesService from '../../services/TlesService';
import OrdersService from '../../services/OrdersService';
import LocationDetail from './LocationDetailDialog';
import {faArrowDown, faArrowUp} from '@fortawesome/pro-regular-svg-icons';
import {TopFrame} from '../TopFrame/TopFrame';
import {ToastService} from 'two-app-ui';

interface RouteProps {
  runId: string;
}

interface State {
  loading: boolean;
  saving: boolean;
  run?: Run;
  stops: Stop[];
  locationDetail?: Location;
  showLocationDetail: boolean;
  redirectInfo?: {
    runId: number;
    orderId?: string;
    taskId?: number;
  };
}

class ReOrderStopsComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  stopsService: StopsService | null = null;
  runsService: RunsService | null = null;
  tasksService: TasksService | null = null;
  ordersService: OrdersService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  tlesService: TlesService | null = null;
  toastService: ToastService | null = null;
  appToolbarRef?: React.RefObject<TopFrame>;

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);
    this.state = {
      loading: false,
      saving: false,
      stops: [],
      locationDetail: undefined,
      showLocationDetail: false,
      run: undefined,
    };

    this.onLocationDetailClick = this.onLocationDetailClick.bind(this);
    this.appToolbarLeftTemplate = this.appToolbarLeftTemplate.bind(this);
    this.appToolbarRightTemplate = this.appToolbarRightTemplate.bind(this);
    this.onDoneClick = this.onDoneClick.bind(this);
    this.updateStops = this.updateStops.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onReturn = this.onReturn.bind(this);
    this.createReorderedTle = this.createReorderedTle.bind(this);

    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.stopsService = this.context.stopsService;
    this.runsService = this.context.runsService;
    this.tasksService = this.context.tasksService;
    this.ordersService = this.context.ordersService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tlesService = this.context.tlesService;
    this.toastService = this.context.toastService;
    this.appToolbarRef = this.context.appToolbarRef;

    const runId = this.props.match.params.runId;

    this.loadData(runId);
  }

  async loadData(runId: string) {
    this.setState({loading: true});
    const promises = [await this.loadRun(runId), await this.loadStops(runId)];
    Promise.all(promises).then(([run, stops]) => {
      if (run && stops) {
        this.initAppToolbar(run as Run);
        this.setState({run: run as Run, stops: stops as Stop[], loading: false});
      }
      this.setState({loading: false});
    });
  }

  async loadRun(id: string) {
    return this.runsService
      ?.getRun(id)
      .then(data => {
        return data as Run;
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        return undefined;
      });
  }

  async loadStops(runId: string) {
    const filters: string[] = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run_id',
        value: runId,
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'line_up',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      filters: filters,
      aggregate: true,
    };

    return this.stopsService
      ?.getStops(params)
      .then(data => {
        return (data?.records as Stop[]) ?? [];
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        return undefined;
      });
  }

  initAppToolbar(run: Run) {
    const title = run?.name ?? 'Re-Order Stops';
    const backTitle = 'Cancel';
    const backUrl = `/run/${run?.id}`;
    this.appToolbarRef?.current?.initAppToolbar({
      title,
      leftTemplate: this.appToolbarLeftTemplate,
      rightTemplate: this.appToolbarRightTemplate,
    });
  }

  onCancel() {
    this.onReturn();
  }
  onReturn() {
    const {run} = this.state;
    this.props.history.push(`/run/${run?.id}`);
  }

  onLocationDetailClick(stopLocation: Location | undefined) {
    this.setState({locationDetail: stopLocation, showLocationDetail: true});
  }

  onPositionChange(index: number, direction: 'up' | 'down') {
    const {stops} = this.state;
    const newIndex = direction === 'up' ? index - 1 : index + 1;

    const updatedStops = [...stops];
    const changedStop = updatedStops.splice(index, 1)[0];
    updatedStops.splice(newIndex, 0, changedStop);

    let lastLineUp = 0;
    for (const stop of updatedStops) {
      stop.line_up = ++lastLineUp;
    }

    this.setState({stops: updatedStops});
  }

  async onDoneClick() {
    const {stops, run} = this.state;
    if (await this.updateStops(stops)) {
      this.onReturn();
    }
  }

  async updateStops(stops: Stop[]) {
    this.setState({saving: true});
    this.appToolbarRef?.current?.setLeftTemplate(() => this.appToolbarLeftTemplate(true));
    this.appToolbarRef?.current?.setRightTemplate(() => this.appToolbarRightTemplate(true));
    return Promise.all(
      stops.map(async (stop: Stop) => {
        const stopPatch: Partial<Stop> = {line_up: stop.line_up};
        return this.stopsService?.updateStop(stop.id!, stopPatch);
      })
    )
      .then(async () => {
        await this.createReorderedTle();
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        this.appToolbarRef?.current?.setLeftTemplate(this.appToolbarLeftTemplate);
        this.appToolbarRef?.current?.setRightTemplate(this.appToolbarRightTemplate);
        this.setState({saving: true});
      });
  }

  async createReorderedTle() {
    const {run} = this.state;
    if (!run) {
      return;
    }
    const tleContent: TleContentNote = {
      title: 'Stops Re-ordered',
      text: 'The order of the stops has been changed.',
    };
    const tle: TimeLineEvent = {
      event_type: 'note',
      entity_type: 'run',
      recorded_by: this.getCurrentUserId(),
      entity_id: String(run.id!),
      content: tleContent,
      recorded_at: new Date(),
    };

    return await this.tlesService?.createTle(tle);
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  getStopNamePrefix(locationType: LocationType) {
    switch (locationType) {
      case 'dealership':
        return 'D';
      case 'end-customer':
        return 'C';
      case 'warehouse':
        return 'W';
      case 'factory':
        return 'F';
      case 'service':
        return 'S';
      case 'other':
        return 'O';
      default:
        return 'O';
    }
  }

  appToolbarLeftTemplate(disabled?: boolean) {
    return (
      <Button
        className="p-button p-py-1 p-px-3 p-button-warning"
        label="Cancel"
        onClick={this.onCancel}
        disabled={disabled}
      />
    );
  }

  appToolbarRightTemplate(saving?: boolean) {
    return (
      <Button
        className="p-button p-py-1 p-px-3 p-button-success"
        label="Done"
        onClick={this.onDoneClick}
        loading={saving}
      />
    );
  }
  renderStopCard(stop: Stop, index: number) {
    const tasks = stop.tasks ?? [];
    const executedTasks = tasks.filter(t => t.executed_on !== null);
    const stopLocation = stop.stop_location;
    return (
      <Card key={'stop' + index + stop.id?.toString()} className={'p-mb-2 re-order-stops-stop-card'}>
        <div className="p-d-flex p-jc-between p-py-2 p-px-3">
          <div className="p-d-flex p-ai-center">
            {index !== 0 && (
              <button className={'p-button p-mr-3'} onClick={() => this.onPositionChange(index, 'up')}>
                <span className={'p-button-icon'}>
                  <FontAwesomeIcon icon={faArrowUp} style={{color: 'white'}} size="2xl" />
                </span>
              </button>
            )}
            {index !== this.state.stops.length - 1 && (
              <button className={'p-button'} onClick={() => this.onPositionChange(index, 'down')}>
                <span className={'p-button-icon'}>
                  <FontAwesomeIcon icon={faArrowDown} style={{color: 'white'}} size="2xl" />
                </span>
              </button>
            )}
          </div>
          <div className="p-text-center">
            <div onClick={() => this.onLocationDetailClick(stopLocation)} className="pointer">
              <span className={'p-pr-2 stop-title p-mx-auto'}>
                {this.getStopNamePrefix(stopLocation?.type ?? 'other')}
              </span>
              <span className={'stop-title'}>{stopLocation?.name ?? ''}</span>
            </div>
            <div onClick={() => this.onLocationDetailClick(stopLocation)} className="pointer">
              <span className={'stop-subtitle p-mx-auto'}>
                {`${stopLocation?.address?.street ?? ''}, ${stopLocation?.address?.suburb ?? ''}`}
              </span>
            </div>
            {stopLocation?.instructions && (
              <div onClick={() => this.onLocationDetailClick(stopLocation)} className="pointer">
                <span className={' stop-subtitle stop-instructions p-mx-auto'}>{`${stopLocation?.instructions}`}</span>
              </div>
            )}
          </div>
          <div className="p-d-flex p-ai-center">
            <span className={' p-mr-3 p-text-bold'}>{`${executedTasks.length}/${tasks.length}`}</span>
          </div>
        </div>
      </Card>
    );
  }

  render() {
    const {stops, loading, saving} = this.state;

    if (loading || saving) {
      return (
        <>
          <div className="overlay">
            <ProgressSpinner className="overlay-spinner" />
          </div>
        </>
      );
    }
    return (
      <>
        {stops &&
          stops.map((stop, index) => {
            return this.renderStopCard(stop, index);
          })}
        <Toast ref={this.toast} />
        <LocationDetail
          location={this.state.locationDetail}
          showDialog={this.state.showLocationDetail}
          onHide={() => this.setState({showLocationDetail: false})}
        />
      </>
    );
  }
}

export default withRouter(ReOrderStopsComponent);
