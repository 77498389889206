import React from 'react';
import './TopFrame.scss';
import {LeftPart} from './LeftPart';
import {CenterPart} from './CenterPart';
import {RightPart} from './RightPart';
import {TwoToolbar} from 'two-app-ui';

interface State {
  title?: string;
  backUrl?: string;
  backTitle?: string;
  leftTemplate?: () => React.JSX.Element;
  rightTemplate?: () => React.JSX.Element;
}
export class TopFrame extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};

    this.initAppToolbar = this.initAppToolbar.bind(this);
  }
  initAppToolbar(params: {
    title?: string;
    backUrl?: string;
    backTitle?: string;
    leftTemplate?: () => React.JSX.Element;
    rightTemplate?: () => React.JSX.Element;
  }) {
    this.setState(() => ({
      title: params.title,
      backUrl: params.leftTemplate ? undefined : params.backUrl,
      backTitle: params.leftTemplate ? undefined : params.backTitle,
      leftTemplate: params.leftTemplate,
      rightTemplate: params.rightTemplate,
    }));
  }

  setLeftTemplate(template: () => React.JSX.Element) {
    this.setState(() => ({leftTemplate: template}));
  }

  setRightTemplate(template: () => React.JSX.Element) {
    this.setState(() => ({rightTemplate: template}));
  }

  render() {
    const {title, leftTemplate, rightTemplate, backTitle, backUrl} = this.state;
    return (
      <TwoToolbar
        left={<LeftPart backUrl={backUrl} backTitle={backTitle} template={leftTemplate} />}
        center={<CenterPart title={title ?? ''} />}
        right={<RightPart template={rightTemplate} />}
      />
    );
  }
}
