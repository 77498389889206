import React from 'react';
import AppContext from '../../context/AppContext';
import {Toast} from 'primereact/toast';
import {ScrollPanel} from 'primereact/scrollpanel';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Address, FreightOrder, QueryParameter} from 'two-core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import './OrderComponent.scss';
import FreightOrdersService from '../../services/FreightOrdersService';
import {Card} from 'primereact/card';
import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import {Skeleton} from 'primereact/skeleton';
import {ToastService} from 'two-app-ui';
import {TopFrame} from '../TopFrame/TopFrame';

interface RouteProps {
  runId: string;
  id: string;
}

interface State {
  loading: boolean;
  freightOrder: FreightOrder | undefined;
}

class OrderComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  freightOrdersService?: FreightOrdersService;
  toastService?: ToastService;
  appToolbarRef?: React.RefObject<TopFrame>;

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);
    this.state = {
      loading: false,
      freightOrder: undefined,
    };

    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.freightOrdersService = this.context.freightOrdersService;
    this.toastService = this.context.toastService;
    this.appToolbarRef = this.context.appToolbarRef;

    const id = this.props.match.params.id;
    this.loadData(id);
  }

  async loadData(orderId: string) {
    await this.loadFreightOrder(orderId);
  }

  async loadFreightOrder(id: string) {
    const filters: string[] = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      filters: filters,
      aggregate: true,
    };

    await this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = (data?.records as FreightOrder[]) ?? [];
        const freightOrder = dataRecords[0];

        const title = freightOrder?.order?.reference ?? '';
        this.initAppToolbar(title);

        this.setState({
          freightOrder: dataRecords[0],
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  initAppToolbar(title: string) {
    const {match} = this.props;
    const runId = match.params.runId;
    const backTitle = 'Run';
    const backUrl = `/run/${runId}`;
    this.appToolbarRef?.current?.initAppToolbar({
      title,
      backUrl,
      backTitle,
    });
  }

  render() {
    const {freightOrder, loading} = this.state;

    const order = freightOrder?.order;
    const shipmentItems = freightOrder?.shipment_items?.filter(si => si !== null) ?? [];

    const panelTemplate = (options: PanelHeaderTemplateOptions) => {
      const className = `${options.className}`;
      const titleClassName = `${options.titleClassName}`;

      return (
        <div className={className}>
          <div className="p-grid p-m-0 w-100">
            <div className="p-col-7 p-pr-3">
              <div className="p-field p-grid">
                <label className={`${titleClassName} p-col-fixed`} style={{width: '120px'}}>
                  code
                </label>
                <div className={`${titleClassName} p-col`}>{freightOrder?.id}</div>
              </div>
              <div className="p-field p-grid">
                <label className={`${titleClassName} p-col-fixed`} style={{width: '120px'}}>
                  reference
                </label>
                <div className={`${titleClassName} p-col`}>{order?.reference}</div>
              </div>
              <div className="p-field p-grid">
                <label className={`${titleClassName} p-col-fixed`} style={{width: '120px'}}>
                  shipping address
                </label>
                <div className={`${titleClassName} p-col`}>
                  <div>{freightOrder?.final_destination?.address?.street}</div>
                  <div>
                    {`${freightOrder?.final_destination?.address?.suburb} ${freightOrder?.final_destination?.address?.postCode} ${
                      freightOrder?.final_destination?.address?.state
                    }`}
                  </div>
                </div>
              </div>
              <div className="p-field p-grid">
                <label className={`${titleClassName} p-col-fixed`} style={{width: '120px'}}>
                  delivery note
                </label>
                <div className={`${titleClassName} p-col`}>
                  <div>{freightOrder?.delivery_note}</div>
                </div>
              </div>
            </div>
            <div className="p-col-5 p-pl-3">
              <div className="p-field p-grid">
                <label className={`${titleClassName} p-col-fixed`} style={{width: '80px'}}>
                  dealer
                </label>
                <div className={`${titleClassName} p-col`}>{freightOrder?.owner_company?.account_number}</div>
              </div>
              <div className="p-field p-grid">
                <label className={`${titleClassName} p-col-fixed`} style={{width: '80px'}}>
                  phone
                </label>
                <div className={`${titleClassName} p-col`}>{(order?.shipping_address as Address).phoneNumber}</div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        {loading && (
          <div className="overlay">
            <ProgressSpinner className="overlay-spinner" />
          </div>
        )}
        <ScrollPanel id="order_component">
          {order && freightOrder && (
            <Panel headerTemplate={panelTemplate}>
              {shipmentItems.length > 0 ? (
                shipmentItems.map((shippingItem, index) => {
                  const cardMargin = index !== shipmentItems.length - 1 ? 'p-mb-3' : '';
                  return (
                    <Card key={'shippingItem' + index + shippingItem?.id?.toString()} className={cardMargin}>
                      <div className="p-d-flex p-ai-center p-jc-between">
                        <div className="p-d-flex p-p-0 p-col-2 p-as-center">
                          <label className="p-mr-2">
                            {index + 1} of {shipmentItems.length}
                          </label>
                        </div>
                        <div className="p-d-flex p-as-center p-col-6 p-p-0">
                          <span>{shippingItem.content ?? ''}</span>
                        </div>
                        <div className="p-d-flex p-as-center p-col-3 p-p-0">
                          <span>
                            {`${shippingItem.dimensions.height} x ${shippingItem.dimensions.width} x ${shippingItem.dimensions.depth}`}
                          </span>
                        </div>
                        <div className="p-d-flex p-p-0 p-col-1 p-as-center p-jc-end">
                          <span>{shippingItem.dimensions.weight}kg</span>
                        </div>
                      </div>
                    </Card>
                  );
                })
              ) : (
                <Card>
                  <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="p-col-12">
                      <Skeleton width="100%" height="1.25rem" />
                    </div>
                  </div>
                </Card>
              )}
            </Panel>
          )}
          <Toast ref={this.toast} />
        </ScrollPanel>
      </>
    );
  }
}

export default withRouter(OrderComponent);
